import config from '../config';
import { preparePostRequest, handleResponse } from '../_helpers';
import qs from 'qs'
import axios from 'axios'

export const scoreCardsService = {
    getScoreCard
};


function getScoreCard(request, type, subType) {
    if (!type && request.hasOwnProperty('type')) {
        type = request.type;
    }
    if (!subType && request.hasOwnProperty('subType')) {
        subType = request.subType;
    }
    delete request['type'];
    delete request['subType'];
    const requestOptions = preparePostRequest(request);

    const uptime = subType === 'uptime' ? 'uptime/' : '';
    return fetch(`${config.apiUrl}scorecard/${uptime}${type}`, requestOptions).then(handleResponse);
}


